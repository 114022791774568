var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navigation"},[_c('a',{class:['hamburger', { 'hamburger--visible': _vm.menuVisible }],attrs:{"href":"#","title":"Rozwiń menu"},on:{"click":function($event){return _vm.menuClick()}}},[_c('div',{staticClass:"hamburger__stripe"}),_c('div',{staticClass:"hamburger__stripe"}),_c('div',{staticClass:"hamburger__stripe"})]),_c('ul',{class:['menu', { 'menu--visible': _vm.menuVisible }]},_vm._l((_vm.navigation),function(item){return _c('li',{key:item.name,class:['menu__item', { 'menu__item--disabled': !item.available }]},[(!item.available)?_c('span',[_c('img',{staticClass:"menu__item--disabled-icon",attrs:{"src":"/img/padlock.svg","alt":"niedostępne"}}),_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(item.available)?_c('router-link',{attrs:{"title":`Link do zakładki: ${item.name}`,"to":{ path: `/${item.path}`, query: _vm.$route.query }},on:{"click":function($event){return _vm.menuClick()}}},[_vm._v(_vm._s(item.name))]):_vm._e(),(
          item.completed !== '1' &&
          item.available &&
          !_vm.safeElements.includes(item.name)
        )?_c('p',{staticClass:"menu__tooltip menu__tooltip--complete"},[_vm._v(" Uzupełnij zakładkę ")]):_vm._e(),(!item.available)?_c('p',{staticClass:"menu__tooltip"},[_vm._v(_vm._s(item.message))]):_vm._e(),(item.completed === '1' && item.available)?_c('svg',{staticClass:"menu__item--completed",attrs:{"viewBox":"0 0 13 10"}},[_c('path',{class:[
            'tick',
            { 'tick--white': `/${item.path}` === _vm.$route.path },
          ],attrs:{"id":"Check","d":"M347.5,21.5,346,23l4,4,9-8.5L357.5,17,350,24Z","transform":"translate(-346 -17)"}})]):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }